import React, { useContext, forwardRef} from 'react';
import { AppContext } from '../contexts/context';
import * as fflate from 'fflate';
import packageInfo from '../../package.json';

import Dialog from '@mui/material/Dialog';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import HealingIcon from '@mui/icons-material/Healing';
import Slide from '@mui/material/Slide';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';

import REPAIR_Schema from '../resources/common/REPAIRDATA/schema.json';
import REPAIR_DataCP from '../resources/common/REPAIRDATA/initialDataCP.json';
import REPAIR_DataP from '../resources/common/REPAIRDATA/initialDataP.json';
import REPAIR_DataPP from '../resources/common/REPAIRDATA/initialDataPP.json';
import REPAIR_DataPR from '../resources/common/REPAIRDATA/initialDataPR.json';
import REPAIR_DataV from '../resources/common/REPAIRDATA/initialDataV.json';
import REPAIR_DataVP from '../resources/common/REPAIRDATA/initialDataVP.json';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AppHelpDialog(props) {
  const [ state, dispatch ] = useContext(AppContext);
  const myURL = new URL(window.location.href);
  const encoder = new TextEncoder();

  var dataSrc = "unknown"
  var appEnv = 'prd'

  const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.0/8 are considered localhost for IPv4.
      window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  );

  const subdomain = window.location.hostname.split('.').slice(0, -2).join('.');

  if (subdomain.includes('remote') || subdomain.includes('dev') || isLocalhost) {
    appEnv = 'dev'
  } else if (subdomain.includes('stg') || subdomain.includes('staging')) {
    appEnv = 'stg'
  } else {
    appEnv = 'prd'
  }

  const repairData = () =>{
    dispatch({type: 'data/urlUpdated', value: myURL.href});
    dispatch({type: 'data/S/updated', value: REPAIR_Schema});
    dispatch({type: 'data/CP/updated', value: REPAIR_DataCP});
    dispatch({type: 'data/P/readOut', value: REPAIR_DataP});
    dispatch({type: 'data/PP/readOut', value: REPAIR_DataPP});
    dispatch({type: 'data/PR/readOut', value: REPAIR_DataPR});
    dispatch({type: 'data/V/updated', value: REPAIR_DataV});
    dispatch({type: 'data/VP/updated', value: REPAIR_DataVP});
    dispatch({type: 'app/ui/dialog/default/close'});
    dispatch({type: 'data/source/set', value: 'app'});
    dispatch({type: 'ndef/scan/done'});
    dispatch({
      type: 'app/ui/snackbar/show',
      message: "Default values for Repair Mode loaded.",
      severity: 'success'
    });

    const nfcSchemaJSON = encoder.encode(JSON.stringify(REPAIR_Schema));
    const nfcSchemaCompressed = fflate.compressSync(nfcSchemaJSON, { level: 6, mem: 8 });
    dispatch({type: 'data/S/updatedCompressed', value: nfcSchemaCompressed});

    console.log('nfcSchemaCompressed:', nfcSchemaCompressed)
  }

  const handleClose = () => {
    dispatch({type: 'app/ui/menu/dialog/default/close'});
  };

  switch(state.nfcDataSource) {
    case 'import':
      dataSrc = 'Shared via a link.';
      break;
    case 'scan':
      dataSrc = 'Scanned from a device.'
      break;
    case 'app':
      dataSrc = 'Loaded via the app.'
      break;
    default:
      dataSrc = "unknown"
      break;
  }

  return (
    <div>
      <Dialog
        fullScreen
        open={true}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Box sx={{ position: 'relative', backgroundColor:"#fff", mb:2 }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon sx={{color:'#3b3b3b'}}/>
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1, color:"#3b3b3b" }} variant="h6" component="div">
              Troubleshoot
            </Typography>
          </Toolbar>
        </Box>
        <Box sx={{paddingInline:2, paddingBottom:2}}>
        <List
          sx={{ width: '100%', maxWidth: 450, }}
          subheader={<ListSubheader sx={{color:state.brandColor1}}>App</ListSubheader>}>
          <ListItem alignItems="flex-start">
            <ListItemText
              primary="URL"
              secondary={
                <React.Fragment>
                  <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                    {myURL.origin}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemText
              primary="Connection"
              secondary={
                <React.Fragment>
                  <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                    {navigator.onLine ? 'Online' : 'Offline'}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemText
              primary="Version"
              secondary={
                <React.Fragment>
                  <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                  { appEnv === 'prd'
              ? <>v{packageInfo.version}-{process.env.REACT_APP_GIT_HASH}</>
              : appEnv === 'stg'
                ? <> v{packageInfo.version} ({process.env.REACT_APP_GIT_HASH}-stg)</>
                : <> v{packageInfo.version} ({process.env.REACT_APP_GIT_HASH}-dev)</>
                }
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
        </List>
          <Divider />
          {state.nfcDataSource !== 'unknown'
          ? <List
              sx={{ width: '100%', maxWidth: 450,}}
              subheader={<ListSubheader sx={{color:state.brandColor1}}>Data</ListSubheader>}>
                {state.nfcTagSn && state.nfcSchema.$id && <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Article Code"
                    secondary={
                      <React.Fragment>
                        <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                        {!state.nfcSchema.$id?"Data unavailable":state.nfcSchema.$id.split("/")[2]}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>}
                {!state.nfcTagSn && <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={!state.nfcTagSn?"No device has been scanned yet.":"NFC Serial number"}
                    secondary={
                      <React.Fragment>
                        <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                        {!state.nfcTagSn?"":state.nfcTagSn}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem> }
                {state.nfcTagSn && state.nfcSchema.$id && <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={!state.nfcTagSn?"No device has been scanned yet.":"NFC Serial number"}
                    secondary={
                      <React.Fragment>
                        <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                        {!state.nfcTagSn?"":state.nfcTagSn}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem> }
                {state.nfcTagSn && <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={state.nfcDataSource === 'import' ? "Import Time" : "Scan Time"}
                    secondary={
                      <React.Fragment>
                        <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                        {!state.nfcDataScanTime?"available only after scan": state.nfcDataScanTime}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem> }
                {state.nfcDataSource === 'import' && <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Config Name"
                    secondary={
                      <React.Fragment>
                        <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                        {state.nfcDataSource === 'import' ? state.uiImportConfigName : "available only after import" }
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem> }
                {state.nfcTagSn && <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Schema Version"
                    secondary={
                      <React.Fragment>
                        <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                        {!state.nfcSchema.$id?"Data unavailable":state.nfcSchema.$id.split("/")[3]}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>}
                {state.nfcTagSn &&<ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Data Source"
                    secondary={
                      <React.Fragment>
                        <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                        {dataSrc}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>}
            </List>
          : <List
              sx={{ width: '100%', maxWidth: 450,}}
              subheader={<ListSubheader sx={{color:state.brandColor1}}>Data</ListSubheader>}>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary="No device data available."
                secondary={
                  <React.Fragment>
                    <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                      A device must be scanned or shared data must be imported.
                    </Typography>
                  </React.Fragment>
                }
              />
              </ListItem>
          </List>
        }
        <Divider />
        <List
          sx={{ width: '100%', maxWidth: 450, }}
          subheader={<ListSubheader sx={{color:state.brandColor1}}>Self-service</ListSubheader>}>
              <ListItem alignItems="flex-start">
            <ListItemText
              primary="Repair Device"
              secondary={
                <React.Fragment>
                  <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                    Loading and writing the repair data causes the data on the control unit to be reinitialised. The operating voltage must be disconnected and reconnected after writing.
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          <Button
            onClick={repairData}
            variant="contained"
            color="secondary"
            startIcon={<HealingIcon />}
            sx={{marginTop:1, marginBottom:1}}
            fullWidth
          >
            Load Repair Data
          </Button>
        </List>
        </Box>
      </Dialog>
    </div>
  );
}
