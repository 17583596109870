import React, { useState, useEffect, useContext} from 'react';
import { AppContext } from '../contexts/context';
import * as fflate from 'fflate';

// Material UI

// Todo(hoffmannm): use stack with mui v5
// import Stack from '@mui/material/Stack';

import Dialog from '@mui/material/Dialog';
import ClearIcon from '@mui/icons-material/Clear';
import HealingIcon from '@mui/icons-material/Healing';
import DefaultValueIcon from '@mui/icons-material/Replay';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { createStyles, makeStyles } from '@mui/styles';

// Data
import SVD0005E_Schema from '../resources/common/SVD0005E/schema.json';
import SVD0005E_DataCP from '../resources/common/SVD0005E/initialDataCP.json';
import SVD0005E_DataP from '../resources/common/SVD0005E/initialDataP.json';
import SVD0005E_DataPP from '../resources/common/SVD0005E/initialDataPP.json';
import SVD0005E_DataPR from '../resources/common/SVD0005E/initialDataPR.json';
import SVD0005E_DataV from '../resources/common/SVD0005E/initialDataV.json';
import SVD0005E_DataVP from '../resources/common/SVD0005E/initialDataVP.json';

import EFA0003E_Schema from '../resources/common/EFA0003E/schema.json';
import EFA0003E_DataCP from '../resources/common/EFA0003E/initialDataCP.json';
import EFA0003E_DataP from '../resources/common/EFA0003E/initialDataP.json';
import EFA0003E_DataPP from '../resources/common/EFA0003E/initialDataPP.json';
import EFA0003E_DataPR from '../resources/common/EFA0003E/initialDataPR.json';
import EFA0003E_DataV from '../resources/common/EFA0003E/initialDataV.json';
import EFA0003E_DataVP from '../resources/common/EFA0003E/initialDataVP.json';

import UK00021E_Schema from '../resources/common/UK00021E/schema.json';
import UK00021E_DataCP from '../resources/common/UK00021E/initialDataCP.json';
import UK00021E_DataP from '../resources/common/UK00021E/initialDataP.json';
import UK00021E_DataPP from '../resources/common/UK00021E/initialDataPP.json';
import UK00021E_DataPR from '../resources/common/UK00021E/initialDataPR.json';
import UK00021E_DataV from '../resources/common/UK00021E/initialDataV.json';
import UK00021E_DataVP from '../resources/common/UK00021E/initialDataVP.json';

import REPAIR_Schema from '../resources/common/REPAIRDATA/schema.json';
import REPAIR_DataCP from '../resources/common/REPAIRDATA/initialDataCP.json';
import REPAIR_DataP from '../resources/common/REPAIRDATA/initialDataP.json';
import REPAIR_DataPP from '../resources/common/REPAIRDATA/initialDataPP.json';
import REPAIR_DataPR from '../resources/common/REPAIRDATA/initialDataPR.json';
import REPAIR_DataV from '../resources/common/REPAIRDATA/initialDataV.json';
import REPAIR_DataVP from '../resources/common/REPAIRDATA/initialDataVP.json';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: 16,
    },
    button: {
      marginTop: 10,
      marginRight: 10,
    },
    closeButton: {
      position: 'absolute',
      right: 8,
      top: 8,
      color: theme.palette.grey[500],
    },
  }),
);

const DevDialog = () => {
  const classes = useStyles();
  const [ state, dispatch ] = useContext(AppContext);
  const [ open, setOpen ] = useState(false);

  const myURL = new URL(window.location.href);
  const encoder = new TextEncoder();

  const setSVD0005E = () =>{
    dispatch({type: 'data/urlUpdated', value: myURL.href});
    dispatch({type: 'data/S/updated', value: SVD0005E_Schema});
    dispatch({type: 'data/CP/updated', value: SVD0005E_DataCP});
    dispatch({type: 'data/P/readOut', value: SVD0005E_DataP});
    dispatch({type: 'data/PP/readOut', value: SVD0005E_DataPP});
    dispatch({type: 'data/PR/readOut', value: SVD0005E_DataPR});
    dispatch({type: 'data/V/updated', value: SVD0005E_DataV});
    dispatch({type: 'data/VP/updated', value: SVD0005E_DataVP});
    dispatch({type: 'app/ui/dialog/default/close'});
    dispatch({type: 'data/source/set', value: 'app'});
    dispatch({type: 'ndef/scan/done'});
    dispatch({
      type: 'app/ui/snackbar/show',
      message: "Default values for SVD0005E loaded.",
      severity: 'success'
    });

    const nfcSchemaJSON = encoder.encode(JSON.stringify(SVD0005E_Schema));
    const nfcSchemaCompressed = fflate.compressSync(nfcSchemaJSON, { level: 6, mem: 8 });
    dispatch({type: 'data/S/updatedCompressed', value: nfcSchemaCompressed});

    console.log('nfcSchemaCompressed:', nfcSchemaCompressed)
  }

  const setEFA0003E = () =>{
    dispatch({type: 'data/urlUpdated', value: myURL.href});
    dispatch({type: 'data/S/updated', value: EFA0003E_Schema});
    dispatch({type: 'data/CP/updated', value: EFA0003E_DataCP});
    dispatch({type: 'data/P/readOut', value: EFA0003E_DataP});
    dispatch({type: 'data/PP/readOut', value: EFA0003E_DataPP});
    dispatch({type: 'data/PR/readOut', value: EFA0003E_DataPR});
    dispatch({type: 'data/V/updated', value: EFA0003E_DataV});
    dispatch({type: 'data/VP/updated', value: EFA0003E_DataVP});
    dispatch({type: 'app/ui/dialog/default/close'});
    dispatch({type: 'data/source/set', value: 'app'});
    dispatch({type: 'ndef/scan/done'});
    dispatch({
      type: 'app/ui/snackbar/show',
      message: "Default values for EFA0003E loaded.",
      severity: 'success'
    });

    const nfcSchemaJSON = encoder.encode(JSON.stringify(state.nfcSchema));
    const nfcSchemaCompressed = fflate.compressSync(nfcSchemaJSON, { level: 6, mem: 8 });
    dispatch({type: 'data/S/updatedCompressed', value: nfcSchemaCompressed});
  }

  const setUK00021E = () =>{
    dispatch({type: 'data/urlUpdated', value: myURL.href});
    dispatch({type: 'data/S/updated', value: UK00021E_Schema});
    dispatch({type: 'data/CP/updated', value: UK00021E_DataCP});
    dispatch({type: 'data/P/readOut', value: UK00021E_DataP});
    dispatch({type: 'data/PP/readOut', value: UK00021E_DataPP});
    dispatch({type: 'data/PR/readOut', value: UK00021E_DataPR});
    dispatch({type: 'data/V/updated', value: UK00021E_DataV});
    dispatch({type: 'data/VP/updated', value: UK00021E_DataVP});
    dispatch({type: 'app/ui/dialog/default/close'});
    dispatch({type: 'data/source/set', value: 'app'});
    dispatch({type: 'ndef/scan/done'});
    dispatch({
      type: 'app/ui/snackbar/show',
      message: "Default values for UK00021E loaded.",
      severity: 'success'
    });

    const nfcSchemaJSON = encoder.encode(JSON.stringify(state.nfcSchema));
    const nfcSchemaCompressed = fflate.compressSync(nfcSchemaJSON, { level: 6, mem: 8 });
    dispatch({type: 'data/S/updatedCompressed', value: nfcSchemaCompressed});
  }

  const resetData = () =>{
    dispatch({type: 'data/urlUpdated', value: myURL.href});
    dispatch({type: 'data/S/updated', value: {}});
    dispatch({type: 'data/CP/updated', value: {}});
    dispatch({type: 'data/P/readOut', value: {}});
    dispatch({type: 'data/PP/readOut', value: {}});
    dispatch({type: 'data/PR/readOut', value: {}});
    dispatch({type: 'data/V/updated', value: {}});
    dispatch({type: 'data/VP/updated', value: {}});
    dispatch({type: 'data/nfcTagSnUpdated', value: null});
    dispatch({type: 'data/source/set', value: 'scan'});
    dispatch({
      type: 'app/ui/snackbar/show',
      message: "All values cleared!",
      severity: 'success'
    });
    //Added to enable Write button
    dispatch({type: 'app/env/resetToNoData'});
  }

  const repairData = () =>{
    dispatch({type: 'data/urlUpdated', value: myURL.href});
    dispatch({type: 'data/S/updated', value: REPAIR_Schema});
    dispatch({type: 'data/CP/updated', value: REPAIR_DataCP});
    dispatch({type: 'data/P/readOut', value: REPAIR_DataP});
    dispatch({type: 'data/PP/readOut', value: REPAIR_DataPP});
    dispatch({type: 'data/PR/readOut', value: REPAIR_DataPR});
    dispatch({type: 'data/V/updated', value: REPAIR_DataV});
    dispatch({type: 'data/VP/updated', value: REPAIR_DataVP});
    dispatch({type: 'app/ui/dialog/default/close'});
    dispatch({type: 'data/source/set', value: 'app'});
    dispatch({type: 'ndef/scan/done'});
    dispatch({
      type: 'app/ui/snackbar/show',
      message: "Default values for Repair Mode loaded.",
      severity: 'success'
    });

    const nfcSchemaJSON = encoder.encode(JSON.stringify(REPAIR_Schema));
    const nfcSchemaCompressed = fflate.compressSync(nfcSchemaJSON, { level: 6, mem: 8 });
    dispatch({type: 'data/S/updatedCompressed', value: nfcSchemaCompressed});

    console.log('nfcSchemaCompressed:', nfcSchemaCompressed)
  }

  const handleClose = () => {
    dispatch({type: 'app/ui/dialog/default/close'});
  };

  useEffect(() => {
    if (state.uiDialog === 'devmode') {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [state.uiDialog]);

  return (
    <div >
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-dev" style={{borderRadius:30}}>
        <DialogTitle id="form-dialog-dev" onClose={handleClose} className={classes.root} variant="h6" sx={{}} >
          Developer Help
        </DialogTitle>
        <DialogContent sx={{flexGrow:1, display:"flex", flexDirection:"column", alignItems:"center"}}>
          <Typography>
          You can restore default values by pressing one of the following buttons.
          </Typography>
        </DialogContent>
        <div className={classes.root} style={{display:"flex", flexDirection:"column", gap:10}}>
          <Button
            onClick={setSVD0005E}
            variant="contained"
            color="primary"
            startIcon={<DefaultValueIcon />}
            className={classes.button}
            fullWidth
          >
            SVD0005E
          </Button>
          <Button
            onClick={setEFA0003E}
            variant="contained"
            color="primary"
            startIcon={<DefaultValueIcon />}
            className={classes.button}
            fullWidth
          >
            EFA0003E
          </Button>
          <Button
            onClick={setUK00021E}
            variant="contained"
            color="primary"
            startIcon={<DefaultValueIcon />}
            className={classes.button}
            fullWidth
          >
            UK00021E
          </Button>
          <Button
            onClick={repairData}
            variant="contained"
            color="secondary"
            startIcon={<HealingIcon />}
            className={classes.button}
            sx={{marginTop:2}}
            fullWidth
          >
            REPAIR DATA
          </Button>
          <Button
            onClick={resetData}
            variant="outlined"
            color="primary"
            startIcon={<ClearIcon />}
            className={classes.button}
            fullWidth
          >
            CLEAR DATA
          </Button>
        </div>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DevDialog;
