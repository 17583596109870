import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../contexts/context';

import PropTypes from 'prop-types';

// Material UI
import { createStyles, makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import AssessmentIcon from '@mui/icons-material/Assessment';
import TuneIcon from '@mui/icons-material/Tune';
import Badge from '@mui/material/Badge';

// JSON Forms
import { JsonForms } from '@jsonforms/react';
import {
  materialRenderers,
  materialCells
} from '@jsonforms/material-renderers';
import uiSchemaV from '../resources/uiSchemaV.json';
import uiSchemaVP from '../resources/uiSchemaVP.json';
import uiSchemaP from '../resources/uiSchemaP.json';
import uiSchemaPR from '../resources/uiSchemaPR.json';
import uiSchemaPP from '../resources/uiSchemaPP.json';

// Components
import { TextCard } from './AppCards';
import { useCountDataKeys, useCheckImportedData } from './AppUtils';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Box>{children}</Box>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    list: {
      margin: 0,
      padding: 0,
      marginBottom: theme.spacing(0),
    },
    listItem: {
      margin: 0,
      padding: 0,
    },
    paper: {
      minHeight: '75vh',
      paddingBottom: 80,
      backgroundColor: theme.palette.background.default,
    },
    subheader: {
      backgroundColor: theme.palette.background.default,
    },
  }),
);

export default function DataDisplay() {
  const classes = useStyles();
  const [ state, dispatch ] = useContext(AppContext);
  const [ currentValidationMode, setCurrentValidationMode] = useState('ValidateAndShow')
  const [ importedReadOnly, setimportedReadOnly] = useState(false);

  var dataImported = false;
  dataImported = useCheckImportedData();

  const handleTabChange = (event, newValue) => {
    dispatch({ type: 'app/ui/dataPage/tab', value: newValue });
  };

  const jsformsConfigOptions = {
    restrict: false,
    trim: false,
    showUnfocusedDescription: true,
    hideRequiredAsterisk: false
   }

  var showV = false;
  var showVP = false;
  var showP = false;
  var showPP = false;
  var showPR = false;

  if (Object.keys(state.nfcDataV).length) {
    if (Object.keys(state.nfcDataV.V).length) {
      showV = true;
    }
  }
  if (Object.keys(state.nfcDataVP).length) {
    if (Object.keys(state.nfcDataVP.VP).length) {
      showVP = true;
    }
  }
  if (Object.keys(state.originP).length) {
    if (Object.keys(state.originP.P).length) {
      showP = true;
    }
  }
  if (Object.keys(state.originPP).length) {
    if (Object.keys(state.originPP.PP).length) {
      showPP = true;
    }
  }
  if (Object.keys(state.originPR).length) {
    if (Object.keys(state.originPR.PR).length) {
      showPR = true;
    }
  }

  useEffect(() => {
    if (!Object.keys(state.nfcDataV).length) {
      dispatch({ type: 'app/ui/dataPage/tab', value: 1 });
    }
  }, [state.nfcDataV, dispatch]);

  useEffect(() => {
    if (Object.keys(state.nfcSchema)[0]) {
      if (dataImported) {
        // Imported
          console.debug('DataDisplay: Data are imported');
          dispatch({type: 'data/source/set', value: 'import'});
          setCurrentValidationMode('ValidateAndHide');
          // Note(Hoffmannm): Imported allowed if user knows PW and CP was shared!
          // setimportedReadOnly(true);
        } else {
          // Scanned
          console.debug('DataDisplay: Data are scanned from NFC');
      }
    }
  }, [state.nfcSchema, dataImported, dispatch]);

  return (
    <Box  className={classes.root}>
      <Tabs
        value={state.uiDataPageTab}
        onChange={handleTabChange}
        textColor="primary"
        indicatorColor="primary"
        variant="fullWidth"
        aria-label="nfc data tabs"
      >
        <Tab icon={<AssessmentIcon />} iconPosition="start" label="Values" {...a11yProps(0)} />
        <Tab icon={<Badge anchorOrigin={{vertical: 'top', horizontal: 'left'}} badgeContent={useCountDataKeys()} color="primary"><TuneIcon /></Badge>} iconPosition="start" label="Parameters" {...a11yProps(1)} />
      </Tabs>

      <TabPanel value={state.uiDataPageTab} index={0}>
        <Paper square className={classes.paper}>
          <List className={classes.list}>
            <ListSubheader
              disableSticky
              sx={{color:state.brandColor1}}
              className={classes.subheader}>
                User
            </ListSubheader>
            { showV
              ? <Box>
                  <ListItem className={classes.listItem}>
                  <JsonForms
                    schema={state.nfcSchema}
                    uischema={uiSchemaV}
                    config={jsformsConfigOptions}
                    data={state.nfcDataV}
                    renderers={materialRenderers}
                    cells={materialCells}
                    readonly
                  />
                  </ListItem>
                </Box>
              : <TextCard Title={"Note"} Text={"No values to show."}/>
            }
            {(showVP && state.settingAuthenticated === true)
              ? <Box>
                  <ListSubheader disableSticky sx={{color:state.brandColor1}} className={classes.subheader}>Service</ListSubheader>
                  <ListItem className={classes.listItem}>
                  <JsonForms
                    schema={state.nfcSchema}
                    uischema={uiSchemaVP}
                    config={jsformsConfigOptions}
                    data={state.nfcDataVP}
                    renderers={materialRenderers}
                    cells={materialCells}
                    readonly
                  />
                  </ListItem>
                </Box>
              : ''
            }
          </List>
        </Paper>
      </TabPanel>
      <TabPanel value={state.uiDataPageTab} index={1}>
        <Paper square className={classes.paper}>
          <List className={classes.list}>
            <ListSubheader
              disableSticky
              sx={{color:state.brandColor1}}
              className={classes.subheader}>
                User
            </ListSubheader>
            { showP
              ? <Box>
                  <ListItem className={classes.listItem}>
                  <JsonForms
                    schema={state.nfcSchema}
                    uischema={uiSchemaP}
                    config={jsformsConfigOptions}
                    data={state.nfcDataP}
                    renderers={materialRenderers}
                    cells={materialCells}
                    validationMode={currentValidationMode}
                    readonly={importedReadOnly}
                    onChange={({ data, errors }) => {
                      if (errors && errors.length > 0) {
                        console.warn('DataDisplay | data/P:', errors);
                        dispatch({ type: 'data/setValidError', value: true})
                      } else {
                        dispatch({ type: 'data/setValidError', value: false})
                      }
                      dispatch({ type: 'data/P/updated', value: data });
                    }}
                  />
                  </ListItem>
                </Box>
              : <Box>
                    <TextCard Title={"Note"} Text={"No values to show."}/>
                </Box>
            }
            <ListSubheader
              disableSticky
              sx={{color:state.brandColor1}}
              className={classes.subheader}>
                User Read-only
            </ListSubheader>
            { showPR
                ? <Box>
                    <ListItem className={classes.listItem}>
                    <JsonForms
                      schema={state.nfcSchema}
                      uischema={uiSchemaPR}
                      config={jsformsConfigOptions}
                      data={state.nfcDataPR}
                      renderers={materialRenderers}
                      cells={materialCells}
                      validationMode={currentValidationMode}
                      readonly={(state.settingAuthenticated === true && !importedReadOnly)?false:true}
                      onChange={({ data, errors }) => {
                      if (errors && errors.length > 0) {
                        console.warn('DataDisplay | data/PR:', errors);
                        dispatch({ type: 'data/setValidError', value: true})
                      } else {
                        dispatch({ type: 'data/setValidError', value: false})
                      }
                      dispatch({ type: 'data/PR/updated', value: data });
                    }}
                    />
                    </ListItem>
                  </Box>
                : <Box>
                    <TextCard Title={"Note"} Text={"No values to show."}/>
                  </Box>
            }
            {(showPP && state.settingAuthenticated === true)
              ? <Box>
                  <ListSubheader
                    disableSticky
                    sx={{color:state.brandColor1}}
                    className={classes.subheader}>
                      Service
                  </ListSubheader>
                  <ListItem className={classes.listItem}>
                  <JsonForms
                    schema={state.nfcSchema}
                    uischema={uiSchemaPP}
                    config={jsformsConfigOptions}
                    data={state.nfcDataPP}
                    renderers={materialRenderers}
                    cells={materialCells}
                    validationMode={currentValidationMode}
                    readonly={importedReadOnly}
                    onChange={({ data, errors }) => {
                      if (errors && errors.length > 0) {
                        console.warn('DataDisplay | data/PP:', errors);
                        dispatch({ type: 'data/setValidError', value: true})
                      } else {
                        dispatch({ type: 'data/setValidError', value: false})
                      }
                      dispatch({type: 'data/PP/updated', value: data});
                    }}
                  />
                  </ListItem>
                </Box>
                : ''
            }
          </List>
        </Paper>
      </TabPanel>
    </Box>
  );
}
