import React, { useState, useEffect, useContext} from 'react';
import { AppContext } from '../contexts/context';

// Material UI

import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { createStyles, makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: 16,
    },
    button: {
      marginTop: 10,
      marginRight: 10,
    },
    closeButton: {
      position: 'absolute',
      right: 8,
      top: 8,
      color: theme.palette.grey[500],
    },
  }),
);

const ImportDialog = () => {
  const classes = useStyles();
  const [ state, dispatch ] = useContext(AppContext);
  const [ open, setOpen ] = useState(false);

  const handleClose = () => {
    dispatch({type: 'app/ui/dialog/default/close'});
  };

  const handleWrite = () => {
    dispatch({type: 'app/ui/dialog/default/close'});
    dispatch({type: 'app/ui/dialog/ndef/show', value: true})
    dispatch({type: 'ndef/scan/scanning'});
    dispatch({type: 'ndef/control/reading'});
  };

  useEffect(() => {
    if (state.uiDialog === 'import') {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [state.uiDialog]);

  return (
    <div >
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-dev" style={{borderRadius:30}}>
        <DialogTitle id="form-dialog-dev" onClose={handleClose} className={classes.root} variant="h6" sx={{}} >
          {'$id' in state.nfcSchema
            ? 'Configuration imported'
            : 'Import in progress'
          }
        </DialogTitle>
        <DialogContent sx={{flexGrow:1, display:"flex", flexDirection:"column", alignItems:"center"}}>
          {
            ('$id' in state.nfcSchema)
            ? <></>
            : <CircularProgress sx={{mt:1, mb:3}}/>
          }
          <Typography>
          {'$id' in state.nfcSchema
            ? 'Shared configuration loaded:'
            : 'Data will be loaded: '
          }
          </Typography>
          <Typography>
           {state.uiImportConfigName}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {'$id' in state.nfcSchema ? 'Show Data' : 'Okay' }
          </Button>
          {
            (state.nfcAvailable === true &&
             state.nfcPermission === 'granted' &&
             '$id' in state.nfcSchema)
            ? <Button onClick={handleWrite} color="primary">
                Write to Device
              </Button>
            : <></>
          }

        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ImportDialog;
