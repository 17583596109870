import { useContext } from 'react';
import { AppContext } from '../contexts/context';
import * as fflate from 'fflate';
import { recordId } from './RecordData';

export function useNdefData () {

  const encoder = new TextEncoder();
  const [ state, dispatch ] = useContext(AppContext);

  return {
    updateWriteData: function () {
      var _nfcUri = state.nfcUri;
      var _nfcSchemaCompressed = state.nfcSchemaCompressed;
      var _nfcDataCP = encoder.encode(JSON.stringify(state.nfcDataCP))
      var _nfcDataP = encoder.encode(JSON.stringify(state.nfcDataP))
      var _nfcDataPP = encoder.encode(JSON.stringify(state.nfcDataPP))
      var _nfcDataPR = encoder.encode(JSON.stringify(state.nfcDataPR))
      var _nfcDataV = encoder.encode(JSON.stringify(state.nfcDataV))
      var _nfcDataVP = encoder.encode(JSON.stringify(state.nfcDataVP))

      const recUri = {
        id: recordId.Uri,
        recordType: "url",
        data: _nfcUri
      }

      const recSchema = {
        id: recordId.S,
        recordType: "mime",
        mediaType: "application/zip",
        data: _nfcSchemaCompressed
      }

      const recCP = {
        id: recordId.CP,
        recordType: "mime",
        mediaType: "application/json",
        data: _nfcDataCP
      };

      const recP = {
        id: recordId.P,
        recordType: "mime",
        mediaType: "application/json",
        data: _nfcDataP
      };

      const recPP = {
        id: recordId.PP,
        recordType: "mime",
        mediaType: "application/json",
        data: _nfcDataPP
      };

      const recPR = {
        id: recordId.PR,
        recordType: "mime",
        mediaType: "application/json",
        data: _nfcDataPR
      };

      const recV = {
        id: recordId.V,
        recordType: "mime",
        mediaType: "application/json",
        data: _nfcDataV
      };

      const recVP = {
        id: recordId.VP,
        recordType: "mime",
        mediaType: "application/json",
        data: _nfcDataVP
      };

      const records = [
        recUri,
        recSchema,
        recCP,
        recP,
        recPP,
        recPR,
        recV,
        recVP
      ]

      // console.debug(`ndefData | updateWriteData`, records)
      return {records}
    },
    checkTag: function ({message, serialNumber}) {
      dispatch({type: `data/checkTag/setStatus`, value: 'pending'});
      console.info("ndefData | CheckTag: Records found:  ", message.records.length);
      console.info("ndefData | CheckTag: Records:  ", message.records);

      var _checkResult = {
        message: 'checkSuccessfullyDone',
        data: 'undefined',
        status: 'failed',
        sn: serialNumber,
        nfcUri: state.nfcUri,
        nfcSchema: state.nfcSchema,
        nfcSchemaCompressed: state.nfcSchemaCompressed,
        nfcDataCP: state.nfcDataCP,
        nfcDataP: state.nfcDataP,
        nfcDataPP: state.nfcDataPP,
        nfcDataPR: state.nfcDataPR,
        nfcDataV: state.nfcDataV,
        nfcDataVP: state.nfcDataVP,
      }

      if (state.nfcSchema.$id === "/schemas/Repair/v1.0.0") {
        _checkResult.status = 'tagOkay'
        _checkResult.message = 'checkSkipped'
        console.warn(`ndefData | CheckTag: Repairdata found. Check skipped!`)
        return _checkResult;
      }

      console.info("ndefData | CheckTag Init: checkResult:  ", _checkResult);

      if (state.settingForceWrite === false) {
        if (message.records.length === 0) {
          console.error("ndefData | checkTag: Empty tag found.");
          _checkResult.message = 'checkErrorEmptyTag';
          return _checkResult;
        }
      }

      // Check SerialNumber
      if (state.settingForceWrite === false) {
        if (state.settingSeamlessWrite === false) {
          if (state.nfcDataSource === 'scan') {
            if (state.nfcTagSn !== serialNumber) {
              console.info("ndefData | checkTag: Serial number not identical")
              console.info("ndefData | checkTag: device serialNumber", serialNumber)
              console.info("ndefData | checkTag: state  serialNumber", state.nfcTagSn)
              _checkResult.message = 'checkWarningDifferentSn';
              _checkResult.data = serialNumber;
              return _checkResult;
            }
          } else {
            console.debug("ndefData | CheckTag: Imported data, serial number check skipped.");
          }
        }
      } else {
        console.debug("ndefData | CheckTag: Force write active, record & SN check skipped.");
      }

      dispatch({type: 'data/nfcTagSnUpdated', value: serialNumber});

      if (state.settingForceWrite) {
        // TODO(hoffmannm): TBD: Add check of all records valid if Force Write is enabled.
        _checkResult.status = 'tagOkay'
        _checkResult.message = 'checkSkipped'
        console.warn(`ndefData | CheckTag: Record check skipped because of enabled 'Force Write'!`)
        return _checkResult;
      }

      // Process Message
      for (const record of message.records) {
        const decoder = new TextDecoder();
        var urlData = null;
        var recordData = {};
        var recordDataJson = null;
        var recordKey = '';

        console.info("ndefData | CheckTag: Record Id:    " + record.id);
        console.info("ndefData | CheckTag: Record type:  " + record.recordType);
        console.info("ndefData | CheckTag: MIME type:    " + record.mediaType);

        switch (record.recordType) {
          case "mime":
            switch (record.mediaType) {
              case "application/json":
                console.info("ndefData | CheckTag: Processing MediaType JSON ...");
                console.info("ndefData | CheckTag: Record Data: ", record.data);
                recordDataJson = decoder.decode(record.data);
                try {
                  recordData = JSON.parse(decoder.decode(record.data));
                } catch (e) {
                  _checkResult.message = 'jsonParseError';
                  console.info("ndefData | readingHandler jsonParseError", _checkResult);
                  return _checkResult;
                }
                break;
              case "application/zip":
                console.info("ndefData | CheckTag: Processing MediaType ZIP ...");
                console.info("ndefData | CheckTag: Record Data: ", record.data);
                const recDataU8 = new Uint8Array(record.data.buffer);
                var decompressed = new Uint8Array();
                try {
                  decompressed = fflate.decompressSync(recDataU8);
                } catch (error) {
                  console.error('ndefData | readingHandler: Error while decompressing data', error);
                }
                console.info("ndefData | CheckTag: Decompressed Data: ", decompressed);
                recordDataJson = decoder.decode(decompressed);
                try {
                  recordData = JSON.parse(recordDataJson);
                } catch (e) {
                  _checkResult.message = 'jsonParseError';
                  console.info("ndefData | CheckTag: jsonParseError", _checkResult);
                  return _checkResult;
                }
                break;
              default:
                console.error(`ndefData | CheckTag: Mediatype ${record.mediaType} not supported.`);
                _checkResult.message = 'checkErrorMediaTypeUnknown';
                _checkResult.data = record.mediaType;
                return _checkResult;
            }

            // Validity check for recordData and recordKey
            if (recordData === null || recordData === undefined) {
                console.error("ndefData | CheckTag: recordData is null or undefined")
                _checkResult.message = 'checkErrorRecordDataInvalid';
                return _checkResult;
            } else {
              if (Object.keys(recordData)[0] === null || Object.keys(recordData)[0] === undefined) {
                console.error("ndefData | CheckTag: recordKey is null or undefined.")
                recordKey = 'InvalidKey';
                _checkResult.data = recordKey;
                _checkResult.message = 'checkErrorRecordKeyInvalid';
                return _checkResult;
              } else {
                recordKey = Object.keys(recordData)[0];
                console.info("ndefData | CheckTag: recordKey", recordKey)
              }
            }

            switch (recordKey) {
              case '$id': // Check Schema ID
                if (recordData.$id === state.nfcSchema.$id) {
                  console.info("ndefData | CheckTag: $id okay")
                } else {
                  console.warn("ndefData | CheckTag: $id check failed")
                  console.info("ndefData | CheckTag: old id", state.nfcSchema.$id)
                  console.info("ndefData | CheckTag: new id", recordData.$id)
                  // Check Device Code
                  if (recordData.$id.split("/")[2] !== state.nfcSchema.$id.split("/")[2]) {
                    _checkResult.message = 'checkDeviceIdFailed';
                    _checkResult.data = recordData.$id.split("/")[2];
                    return _checkResult;
                  }
                  let srcVersion = (state.nfcSchema.$id.split("/")[3]).split(".")[0]
                  let destVersion = (recordData.$id.split("/")[3])
                  // Check Data Version
                  if (destVersion.split(".")[0] !== srcVersion.split(".")[0]) {
                    console.debug(srcVersion.split(".")[0], "Old major version")
                    console.debug(destVersion.split(".")[0], "New major version")
                    _checkResult.message = 'checkVersionIdFailed';
                    _checkResult.data = destVersion;
                    return _checkResult;
                  }
                }
                console.info("ndefData | CheckTag: Schema Updated ...");
                dispatch({type: 'data/S/updated', value: recordData});
                _checkResult.nfcSchemaCompressed = record.data;
                break;
              default: // Check Record Data
                if (!(recordKey in recordId)) {
                  // Todo(hoffmannm): Add error state for warings. Recommend user to read out the tag again.
                  // Show latest waring in troubleshooting.
                  console.warn(`ndefData | CheckTag: Unexpected record Key ${recordKey} found in id ${record.id}.`);
                  dispatch({
                    type: 'app/ui/snackbar/show',
                    message: `Something was odd. Read again and check the data! [${recordKey}@${record.id}]`,
                    severity: 'warning'
                  });
                } else {
                  if (state.nfcDataSource === 'import') {
                    _checkResult.message = 'checkDataImported';

                    var checkSchemaP = 0;
                    var checkSchemaPP = 0;
                    var checkSchemaPR = 0;

                    var checkDataP = 0;
                    var checkDataPP = 0;
                    var checkDataPR = 0;

                    if (Object.keys(state.nfcDataP).length) {
                      checkSchemaP = Object.keys(state.nfcSchema.properties.P.properties).length;
                      checkDataP = Object.keys(state.nfcDataP.P).length;
                      console.debug("ndefData | CheckTag after Import: checkSchemaP / checkDataP", checkSchemaP, checkDataP);
                    }

                    if (Object.keys(state.nfcDataPP).length) {
                      checkSchemaPP = Object.keys(state.nfcSchema.properties.PP.properties).length;
                      checkDataPP = Object.keys(state.nfcDataPP.PP).length;
                      console.debug("ndefData | CheckTag after Import: checkSchemaPR / checkDataPR", checkSchemaPR, checkDataPR);
                    }

                    if (Object.keys(state.nfcDataPR).length) {
                      checkSchemaPR = Object.keys(state.nfcSchema.properties.PR.properties).length;
                      checkDataPR = Object.keys(state.nfcDataPR.PR).length;
                      console.debug("ndefData | CheckTag after Import: checkSchemaPP / checkDataPP", checkSchemaPP, checkDataPP);
                    }

                    console.info(`ndefData | CheckTag after Import: recordData - ${recordKey}`, recordData)
                    switch (recordKey) {
                      case 'CP':
                        _checkResult.nfcDataCP = recordData;
                        break;
                      case 'P':
                        if (checkSchemaP !== checkDataP) {
                          // Merge Parameters if import only contains some of the parameters.
                          recordData.P = {...recordData.P, ...state.nfcDataP.P};
                          _checkResult.nfcDataP = recordData;
                          console.debug("ndefData | CheckTag after Import: recordData.P combined")
                        } else if (checkSchemaP === 0) {
                          // Replace empty parameter objects with read out record data.
                          _checkResult.nfcDataP = recordData;
                        } else {
                          console.debug("ndefData | CheckTag after Import: nfcData.P from import used", _checkResult.nfcDataP)
                        }
                        break;
                      case 'PP':
                        if (checkSchemaPP !== checkDataPP) {
                          // Merge Parameters if import only contains some of the parameters.
                          recordData.PP = {...recordData.PP, ...state.nfcDataPP.PP};
                          _checkResult.nfcDataPP = recordData;
                          console.debug("ndefData | CheckTag after Import: recordData.PP combined")
                        } else if (checkSchemaPP === 0) {
                          // Replace empty parameter objects with read out record data.
                          _checkResult.nfcDataPP = recordData;
                        } else {
                          console.debug("ndefData | CheckTag after Import: nfcData.PP from import used", _checkResult.nfcDataPP)
                        }
                        break;
                      case 'PR':
                        if (checkSchemaPR !== checkDataPR) {
                          // Merge Parameters if import only contains some of the parameters.
                          recordData.PR = {...recordData.PR, ...state.nfcDataPR.PR};
                          _checkResult.nfcDataPR = recordData;
                          console.debug("ndefData | CheckTag after Import: recordData.PR combined")
                        } else if (checkSchemaPR === 0) {
                          // Replace empty parameter objects with read out record data.
                          _checkResult.nfcDataPR = recordData;
                        } else {
                          console.debug("ndefData | CheckTag after Import: nfcData.PR from import used", _checkResult.nfcDataPR)
                        }
                        break;
                      case 'V':
                        _checkResult.nfcDataV = recordData;
                        break;
                      case 'VP':
                        _checkResult.nfcDataVP = recordData;
                        break;
                      default:
                        console.warn(`ndefData | CheckTag: No Import process defined for ${recordKey} with id ${record.id}.`)
                        dispatch({
                          type: 'app/ui/snackbar/show',
                          message: `Something was odd. Read again and check the data! [${recordKey}@${record.id}]`,
                          severity: 'warning'
                        });
                    }
                  } else {
                    // If the data was not previously imported, then there is probably nothing to do here.
                    console.debug(`ndefData | CheckTag: Found ${record.id}:${recordKey} in the tag.`)
                  }
                }
                break;
            }
            break;
          case "url":
            urlData = decoder.decode(record.data);
            console.debug("ndefData | CheckTag: URL saved.", urlData)
            _checkResult.nfcUri = urlData;
            break;
          case "empty":
            console.error("ndefData | checkTag: Empty Record found found.");
            _checkResult.message = 'checkErrorRecordEmpty';
            _checkResult.data = record.recordType;
            return _checkResult;
          case "smart-poster":
          default:
            console.debug(`ndefData | CheckTag: Recordtype ${record.recordType} not supported.`);
            _checkResult.message = 'checkErrorRecordTypeUnkown';
            _checkResult.data = record.recordType;
            return _checkResult;
        }
      }

      _checkResult.status = 'tagOkay'
      console.debug('ndefData | CheckTag: _checkResult is', _checkResult)
      return _checkResult;
    },
    readingHandler: function ({message, serialNumber}) {
      // TODO(hoffmannm): Fix error handling like checktag!
      // Process Tag SN
      console.info("ndefData | readingHandler Serial Number:", serialNumber);
      dispatch({type: 'data/nfcTagSnUpdated', value: serialNumber});

      const readResult = {
        message: 'undefined',
        data: 'undefined',
        status: 'failed',
        sn: serialNumber,
      }

      if (message.records.length === 0) {
        console.error("ndefData | readingHandler Empty tag found.");
        readResult.message = 'checkErrorEmptyTag';
        return readResult;
      } else {
        console.info("ndefData | readingHandler Records found:  " + message.records.length);
        // Process Message
        for (const record of message.records) {
          const decoder = new TextDecoder();
          var urlData = null;
          var recordData = {};
          var recordDataJson = null;

          console.info("ndefData | readingHandler Record Id:    " + record.id);
          console.info("ndefData | readingHandler Record type:  " + record.recordType);
          console.info("ndefData | readingHandler MIME type:    " + record.mediaType);

          switch (record.recordType) {
            case "mime":
              switch (record.mediaType) {
                case "application/json":
                  console.info("ndefData | readingHandler Processing MediaType JSON ...");
                  console.info("ndefData | readingHandler Record Data: ", record.data);
                  recordDataJson = decoder.decode(record.data);
                  try {
                    recordData = JSON.parse(decoder.decode(record.data));
                  } catch (e) {
                    readResult.message = 'jsonParseError';
                    readResult.status = 'failed';
                    console.error("ndefData | readingHandler jsonParseError", readResult);
                    return readResult;
                  }
                  break;
                case "application/zip":
                  console.info("ndefData | readingHandler Processing MediaType ZIP ...");
                  console.info("ndefData | readingHandler Record Data: ", record.data);
                  const recDataU8 = new Uint8Array(record.data.buffer);
                  var decompressed = new Uint8Array();
                  try {
                    decompressed = fflate.decompressSync(recDataU8);
                  } catch (error) {
                    console.error('ndefData | readingHandler: Error while decompressing data', error);
                  }
                  console.info("ndefData | readingHandler Decompressed Data: ", decompressed);
                  recordDataJson = decoder.decode(decompressed);
                  try {
                    recordData = JSON.parse(recordDataJson);
                  } catch (e) {
                    readResult.message = 'jsonParseError';
                    readResult.status = 'failed';
                    console.error("ndefData | readingHandler jsonParseError", readResult);
                    return readResult;
                  }
                  break;
                default:
                  console.warn(`ndefData | ERROR: Mediatype ${record.mediaType} not supported.`);
                  readResult.message = 'checkErrorMediaTypeUnknown';
                  readResult.data = record.mediaType;
                  break;
              }
              console.info("ndefData | readingHandler === data ===\n" + recordDataJson);
              console.info("ndefData | readingHandler === data ===\n", recordData);

              let recordKey = Object.keys(recordData)[0];

              if (recordKey === '$id') {
                console.info("ndefData | readingHandler Schema Updated ...");
                dispatch({type: 'data/S/updated', value: recordData});
                dispatch({type: 'data/S/updatedCompressed', value: record.data});
                readResult.status = 'done';
                readResult.message = 'readSuccessfullyDone';
              } else {
                if (recordKey in recordId) {
                  switch (recordKey) {
                    case 'P':
                    case 'PR':
                    case 'PP':
                      dispatch({type: `data/${recordKey}/readOut`, value: recordData});
                      break;
                    default:
                      dispatch({type: `data/${recordKey}/updated`, value: recordData});
                      break;
                  }
                  readResult.status = 'done';
                  readResult.message = 'readSuccessfullyDone';
                } else {
                  console.warn("ndefData | readingHandler: Unknown record data found in id ", record.id, recordData)
                  readResult.status = 'failed';
                  readResult.message ='checkErrorRecordKeyUnknown';
                  readResult.data = recordKey;
                }
              }
              break;
            case "url":
              urlData = decoder.decode(record.data);
              console.debug("ndefData | readingHandler URL", urlData)
              dispatch({type: 'data/urlUpdated', value: urlData});
              readResult.status = 'done';
              readResult.message = 'readSuccessfullyDone';
              break;
            case "empty":
              console.error("ndefData | readingHandler: Empty Record found found.");
              readResult.message = 'checkErrorRecordEmpty';
              readResult.data = record.recordType;
              break;
            case "smart-poster":
            default:
              console.warn(`ndefData | readingHandler ERROR Recordtype ${record.recordType} not supported.`);
              readResult.message = 'checkErrorRecordTypeUnkown';
              readResult.data = record.recordType;
              break;
          }
        }
        return readResult;
      }
    }
  }
}
